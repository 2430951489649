import React from 'react'
import Hero from '../components/common/hero'
import axios from 'axios'
import apiUrl from '../services/apiUrl'
import parse from 'html-react-parser'

function AboutUsPage() {

    const [aboutUs, setaboutUs] = React.useState('')

    React.useEffect(() => {
      getAboutUs()
    }, [])
    


    const getAboutUs = async () => {
        try {
            var response = await axios.post(apiUrl.getCms, { category_id: 13 })
            if (response?.data?.data?.cms) {
                setaboutUs(response?.data?.data?.cms[0])
            }
        } catch (error) {
            console.error(error)

        }
    }








    return (
        <>
            <Hero title="ABout us" />
            <div className='container'>
                <h3 className='text-center my-5 fw-bold'>ABOUT US</h3>
                <p className='mt-3'> {parse(`${aboutUs?.content || ''}`)}</p>


            </div>

        </>
    )
}

export default AboutUsPage