import React, { useState, useEffect } from 'react'

import Slider from "react-slick";

import hospitalIcon from '../../assets/images/hospital-icon.svg'
import shadeF from '../../assets/images/shade-f.svg'
import shadeB from '../../assets/images/shade-b.svg'
import placeHolder from '../../assets/images/placeholder.png'
import doctorPlaceHolder from '../../assets/images/doctorplaceholder.png'

import TeleDoctorPopup from '../popup/teleDoctorBooking';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../../assets/css/topdoctors2.scoped.css'
import axios from 'axios';
import apiUrl from '../../services/apiUrl';
import { Link } from 'react-router-dom';

function Topdoctors2() {
    const [topTeleMedcineDoctors, settopTeleMedcineDoctors] = useState([])
    const [showPopup, setshowPopup] = useState(false)
    const [selectedDoctor, setselectedDoctor] = useState('')


    const hidePopup = () => setshowPopup(false)

    useEffect(() => {
        getTelemedicineDoctor()
    }, [])



    const showPopupHandler = (id) => {
        setselectedDoctor(id)
        setshowPopup(true)
    }

    const hidePopupHandler = () => setshowPopup(false)







    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );

    const getTelemedicineDoctor = async () => {
        try {
            var response = await axios.get(apiUrl.getTopTelemedicineDoctor)
            console.log(response.data)
            if (response?.data?.data?.telemedicineDoctors) {
                settopTeleMedcineDoctors(response.data.data.telemedicineDoctors)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrow: true,
        autoplay: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    return (
        <>
            {showPopup && <TeleDoctorPopup showPopup={showPopup} hidePopup={hidePopupHandler} doctorId={selectedDoctor} />}
            <section>
                <div className="col-12 top-doctor-contr main-pd text-center">
                    <div className="tl-contr to-top">
                        <h1 className="text-center">Top Telemedicine Doctors</h1>
                    </div>
                    <div className="row testimonial">
                        <Slider {...settings}>

                            {topTeleMedcineDoctors.map(item =>
                                <div key={item.id} className="col-3 position-relative plml-botm">
                                    <div className="shade">
                                        <div className="shade-bg"></div>
                                        <div className="shade-fn"></div>
                                        {/* <img src={shadeF} alt="" className="shade-frnt" /> */}
                                        {/* <img src={shadeB} alt="" className="shade-back" /> */}
                                    </div>
                                    <div className="position-relative ds-img">
                                        <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : doctorPlaceHolder} width="390" height="450" alt="" />
                                        <div className="botm-content">
                                            <button type="button" className="book-now t-600" onClick={() => showPopupHandler(item?.id)}>Book Now</button>
                                            <div className="ds-cn">
                                                <Link to={`/doctor/${item.id}`} style={{ cursor: 'pointer' }}>
                                                    <h3 className='text-white'>{item.name}</h3>
                                                    <p className='text-white'>{item.experience}Yrs of Exp.</p>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="hospital">

                                        <div className='d-flex justify-content-between'>
                                        <img className='hlogo' src={hospitalIcon} alt="" />
                                        <img className='pt-3' src={item?.hospital?.logo ? `${apiUrl.host}/storage/${item.hospital?.logo}` : doctorPlaceHolder} className="hs-logo-im" alt="" />

                                        </div>
                                        

                                        <div className='text-wrap hs-content w-100'>{item?.hospital?.title},{item?.hospital?.cityDetails?.name}<br />
                                            <p>hey</p>



                                        </div>



                                    </div>
                                </div>)}


                        </Slider>

                    </div>
                </div>
            </section>
        </>

    )
}

export default Topdoctors2







