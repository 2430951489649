
import React, { useEffect, useState } from 'react'
import Hero from '../components/common/hero'
import { useSelector } from 'react-redux'

import '../assets/css/hospital/hospitallist.scoped.css'
import placeHolder from '../assets/images/placeholder.png'
import axios from 'axios'
import apiUrl from '../services/apiUrl'
import Faq from '../components/common/faq'

import { Link } from 'react-router-dom'

import { Skeleton, Empty, Pagination } from 'antd'
import 'antd/lib/skeleton/style/index.css'
import 'antd/lib/pagination/style/index.css'
import 'antd/lib/empty/style/index.css'

import HospitalBookPopup from '../components/popup/hospitalBook'

function Hospitallist() {
    const searchText = useSelector(state => state.searchText.value)
    const isSearch = useSelector(state => state.isSearch.value)
    const [hospitalList, sethospitalList] = useState([])
    const [faqs, setfaqs] = useState([])
    const [isLoading, setisLoading] = useState(false);
    const [selectedHospital, setselectedHospital] = useState('')
    const [showHospitalEnquiry, setshowHospitalEnquiry] = useState(false)

    let PageSize = 6

    const [currentPage, setCurrentPage] = useState(1)

    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return hospitalList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, hospitalList, isLoading])


    useEffect(() => {
        getHospitalList()
    }, [isSearch])

    useEffect(() => {
        getHopspitalFaq()
    }, [])

    const getHospitalList = async () => {
        setisLoading(true)
        try {
            var response = await axios.post(apiUrl.getHospitalSearch,
                { tab_id: 2, search_text: searchText })
            if (response?.data?.data?.hospitalsdetails) {
                sethospitalList(response.data.data.hospitalsdetails)

            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)
        }
    }

    const filterHospital = (data) => {

        var tempHospital = [...hospitalList]
        console.log("data", data, tempHospital)
        if (data.type === 'Z') {
            tempHospital.sort((a, b) => b[data.item].localeCompare(a[data.item]))
            sethospitalList(tempHospital)
        } else {
            tempHospital.sort((a, b) => a[data.item].localeCompare(b[data.item]))
            sethospitalList(tempHospital)
        }
    }

    const getHopspitalFaq = async () => {
        try {
            var response = await axios.get(apiUrl.getAllHospitalFaq)
            if (response.data?.data?.allHospitalFaq) {
                setfaqs(response.data.data.allHospitalFaq)
            }
        } catch (error) {
            console.log(error)

        }
    }

    const hidePopup = () => setshowHospitalEnquiry(false)


    return (
        <>
            <Hero showButton={false} />
            {showHospitalEnquiry && <HospitalBookPopup hospitalId={selectedHospital} showPopup={showHospitalEnquiry} hidePopup={hidePopup} />}
            <section>
                <div className="row m-0 main-pd">
                    <div className="tl-contr float-start w-100 p-0">
                        <div className="text-start float-start m-fl">
                            <h1>Find a Hospital</h1>
                            {/* <p>Lorem ipsum simply text dummy</p> */}
                            <h2>Listing {hospitalList.length} Hospital(s)</h2>
                        </div>
                        <div className="float-end ml-top">
                            <label className="t-600">Sort By :</label>
                            <ul className="country-contr">
                                {/* <li>
                                    <select onChange={(e) => filterHospital({ item: 'country_name', type: e.target.value })}>
                                        <option hidden>Country</option>
                                        <option value="" disabled>Select Country</option>
                                        <option value="A">A-Z</option>
                                        <option value="Z">Z-A</option>

                                    </select>
                                </li> */}
                                <li>
                                    <select onChange={(e) => filterHospital({ item: 'city_name', type: e.target.value })}>
                                        <option hidden>City</option>
                                        <option value="" disabled>Select City</option>
                                        <option value="A">A-Z</option>
                                        <option value="Z">Z-A</option>
                                    </select>
                                </li>
                                <li>
                                    <select onChange={(e) => filterHospital({ item: 'department_name', type: e.target.value })}>
                                        <option hidden>Speciality</option>
                                        <option value="" disabled>Select Speciality</option>
                                        <option value="A">A-Z</option>
                                        <option value="Z">Z-A</option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row packages main-pd text-center">

                    <Skeleton active loading={isLoading}>
                        {currentTableData.map(item =>
                            <div className="col-4 hss-list" key={item.id}>
                                <div className="item-list">
                                    <div className="hs-div">
                                        <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" height="240" />
                                        <h2>{item?.title || ''}</h2>
                                        <div className="content text-start">
                                            {item.address && <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.0446 3.04504C14.2871 1.28758 11.9505 0.319702 9.46506 0.319702C6.97965 0.319702 4.64292 1.28758 2.8855 3.04504C1.12803 4.80255 0.160156 7.1392 0.160156 9.62456C0.160156 14.6524 4.91414 18.8344 7.46817 21.081C7.82309 21.3932 8.12959 21.6629 8.37378 21.891C8.67972 22.1767 9.07241 22.3197 9.46501 22.3197C9.85771 22.3197 10.2503 22.1767 10.5563 21.891C10.8005 21.6628 11.107 21.3932 11.4619 21.081C14.0159 18.8343 18.7699 14.6524 18.7699 9.62456C18.7699 7.1392 17.802 4.80255 16.0446 3.04504ZM10.6107 20.1135C10.248 20.4325 9.93479 20.7081 9.67655 20.9493C9.55791 21.06 9.37212 21.06 9.25344 20.9493C8.99524 20.708 8.68199 20.4325 8.31929 20.1134C5.91819 18.0013 1.44879 14.0697 1.44879 9.6246C1.44879 5.20449 5.04481 1.60847 9.46497 1.60847C13.8851 1.60847 17.4811 5.20449 17.4811 9.6246C17.4812 14.0697 13.0118 18.0013 10.6107 20.1135Z" fill="#E6771B" />
                                                <path d="M9.46384 5.20837C7.20278 5.20837 5.36328 7.04783 5.36328 9.30889C5.36328 11.57 7.20278 13.4094 9.46384 13.4094C11.7249 13.4094 13.5644 11.57 13.5644 9.30889C13.5644 7.04783 11.7249 5.20837 9.46384 5.20837ZM9.46384 12.1206C7.9134 12.1206 6.652 10.8593 6.652 9.30885C6.652 7.75845 7.9134 6.49705 9.46384 6.49705C11.0143 6.49705 12.2756 7.75845 12.2756 9.30885C12.2756 10.8593 11.0143 12.1206 9.46384 12.1206Z" fill="#E6771B" />
                                            </svg>}
                                            {` ${item?.address ?? ''}`}</div>
                                        <div className="overlay">

                                            <div className="text">
                                                <Link to={`/hospital/${item.id}/`}>

                                                    <h2 className='text-center'>{item?.title ?? ''}</h2>
                                                    <h3> {item?.address ?? ''}</h3>
                                                    <ul>
                                                        <li>{item?.departmentCount ?? ''} Departments</li>
                                                        <li>{item?.doctorCount ?? ''} Doctors</li>
                                                    </ul>
                                                    Explore More <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.89299 5.92569L1.65187 0.699063C1.48015 0.527919 1.20213 0.528206 1.0307 0.699948C0.859403 0.871667 0.859846 1.14984 1.03159 1.32112L5.96074 6.23666L1.03141 11.1522C0.859691 11.3235 0.859248 11.6015 1.03053 11.7732C1.11646 11.8593 1.22905 11.9024 1.34163 11.9024C1.45393 11.9024 1.56607 11.8596 1.65185 11.7741L6.89299 6.54761C6.9757 6.46532 7.02211 6.35334 7.02211 6.23666C7.02211 6.11998 6.97557 6.00813 6.89299 5.92569Z" fill="black" />
                                                    </svg>
                                                </Link>


                                                <button onClick={() => { setselectedHospital(item?.id); setshowHospitalEnquiry(true) }} type="button" className="book-now">Book an Appointment <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.74065 6.28738L1.49953 1.06076C1.32781 0.889613 1.04979 0.889901 0.878358 1.06164C0.70706 1.23336 0.707502 1.51153 0.879244 1.68281L5.8084 6.59835L0.879067 11.5139C0.707347 11.6852 0.706905 11.9632 0.878181 12.1349C0.964118 12.221 1.0767 12.264 1.18929 12.264C1.30158 12.264 1.41372 12.2213 1.4995 12.1358L6.74065 6.9093C6.82335 6.82702 6.86976 6.71503 6.86976 6.59835C6.86976 6.48168 6.82322 6.36982 6.74065 6.28738Z" fill="white" />
                                                </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}

                        {!hospitalList.length && <Empty />}



                    </Skeleton>


                    <div className="text-end">
                        <Pagination style={{ borderColor: 'red' }} size={'large'} current={currentPage} total={hospitalList.length} onChange={page => setCurrentPage(page)} />

                    </div>




                </div>
            </section>
            <Faq faqlist={faqs} />
        </>
    )
}

export default Hospitallist
