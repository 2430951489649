import React, { useState, useEffect } from 'react'


//icons

import placeHolder from '../../assets/images/placeholder.png'

import awardLineOne from '../../assets/images/award-line1.svg'
import awardCenter from '../../assets/images/award-center.png'
import awardLineTwo from '../../assets/images/award-line2.svg'


import '../../assets/css/awardsection.scoped.css'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'
import { Link } from 'react-router-dom'


function Awardsection() {

    const [awardList1, setawardList1] = useState([])
    const [awardList2, setawardList2] = useState([])


    useEffect(() => {
        getAWards()
    }, [])


    const getAWards = async () => {
        try {
            var response = await axios.post(apiUrl.getCms, { category_id: 5 })
            if (response?.data?.data?.cms) {
                setawardList1(response.data.data.cms.slice(0, 2))
                setawardList2(response.data.data.cms.slice(2))
            }
        } catch (error) {
            console.error(error)
        }
    }


    return (
        <section>
            <div className="award-section">
                <div className="row text-center news-title m-0">
                    <div className="tl-contr to-top">
                        <h1>Awards & Recognations</h1>
                    </div>
                </div>
                <div className="d-flex main-pd ns-contr">
                    <div className="award">

                        {awardList1.map(item =>
                            <div key={item.id} className="award-list float-start">
                                <Link to={`award/${item?.id}`} >
                                <img src={item?.image ? `${apiUrl.host}/storage/${item?.image}` : placeHolder} className="ml" />
                                <div className="position v-center">
                                    <img src={`${apiUrl.host}/storage/${item?.logo}`} alt="" />
                                    <h2>{item?.title || ''}</h2>
                                </div>
                                </Link>
                            </div>)}

                    </div>
                    <div className="award-center">
                        <div className="v-center position-relative">
                            <img src={awardLineOne} alt="" className="l1" />
                            <img src={awardCenter} alt="" className="l2" />
                            <img src={awardLineTwo} alt="" className="l3" />
                        </div>
                    </div>


                    <div className="award">
                        {awardList2.map(item =>
                            <div key={item.id} className="award-list float-end">
                                <img src={item?.image ? `${apiUrl.host}/storage/${item?.image}` : placeHolder} className="ml" />

                                <div className="position v-center">
                                    <img src={`${apiUrl.host}/storage/${item?.logo}`} alt="" />
                                    <h2>{item?.title || ''}</h2>
                                </div>
                            </div>)}



                    </div>

                </div>
            </div>
        </section>

    )
}

export default Awardsection
