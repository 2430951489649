// live
//const host = 'https://marlin-medical.ipixsolutions.net'

// dev
const host = 'https://dev.admin.marline-medical.ipixsolutions.net'

//proxy
//const host = ''

const uri = '/api/'
const url = `${host}${uri}`

module.exports = {
    host: host,
    getContactUS: `${url}settings/contactUs`,
    getBranchList: `${url}branch/list`,
    getCountry: `${url}country/listCountry`,
    getTopHospital: `${url}country/CountryBasedhospital`,
    getTopDoctor: `${url}country/CountryBasedBestDoctor`,
    getBlogList: `${url}blogs/list`,
    getBlogCategory: `${url}blogs/blogCategoryListing`,
    getBlogListByCategory: `${url}blogs/categoryBasedBlogList`,
    getMedcineList: `${url}medicines/list`,
    getTopBrand: `${url}medicinebrands/topmedicineBrands`,
    getDepartmentList: `${url}department/listDepartments`,
    getTreatmentList: `${url}treatment/searchTreatment`,
    getBestHospitalForTreatementDieses: `${url}treatment/bestHospitalForTreatment`,
    getBestDoctorForTreatementDieses: `${url}treatment/bestDoctorForTreatment`,
    getTreatmentPlan: `${url}treatment/treatmentPlans`,
    setTreatmentEnquiry: `${url}treatment/treatmentEnquiry`,
    setContactEnquiry: `${url}enquiry/contactEnquiry`,
    getFooterData: `${url}settings/listAllSettings`,
    setCommonEnquiry: `${url}enquiry/commonEnquiry`,
    setBizzEnquiry: `${url}enquiry/Mmma_Biz`,
    getTreatmentDises: `${url}treatment/treatmentDiseases`,
    getTreatmentDiseasesDiscription: `${url}treatment/treatmentDiseasesDescription`,
    getCms: `${url}cms/cmsView`,
    getFaq: `${url}faq/listFaq`,
    getHospitalFaq:`${url}hospital/HospitalFaq`,
    getBlogFaq: `${url}blogs/allBlogsFaq`,
    getSingleBlogFaq: `${url}blogs/BlogFaq`,
    getTreatmentFaq: `${url}treatment/allTreatmentFaq`,
    getSingleTreatmentFaq: `${url}treatment/TreatmentFaq`,
    getAllDoctorFaq: `${url}doctor/allDoctorFaq`,
    getAllHospitalFaq: `${url}hospital/allHospitalFaq`,
    getHospitalSearch: `${url}country/hospitalSearch`,
    getTopTelemedicineDoctor: `${url}doctor/telemedicineDoctors`,

    getStateListByCountry: `${url}country/listState`,
    getCityByState: `${url}country/listStateBasedCity`,
    getDoctorSearch: `${url}country/doctorSearch`,
    getDoctorDetails: `${url}doctor/view`,
    getBlogDetails: `${url}hospital/view`,
    getSpecialityDoctor: `${url}doctor/specialityDoctors`,
    postDoctorEnquiry: `${url}doctor/doctorEnquiry`,
    getDoctorConsultation: `${url}doctor/consultation`,
    getSimiliarDoctor: `${url}doctor/similarDoctors`,
    getDoctorSpeciality: `${url}doctor/doctorcoreSpeciality`,
    getDoctorPatientVoice: `${url}doctor/doctorPatientVoice`,
    getDoctorHospital: `${url}doctor/doctorsHospital`,
    getDoctorAwardPublication: `${url}doctor/awardsAndPublication`,
    getHospitalDetails: `${url}hospital/view`,
    postHospitalEnquiry: `${url}hospital/hospital-ExploreEnquiry`,
    getHospitalDoctor: `${url}hospital/hospitalDoctors`,
    getHospitalSimiliarHospital: `${url}hospital/similarHospitals`,
    getHospitalDepartment: `${url}hospital/hospitalDepartments`,
    getHospitalSuccessStories: `${url}hospital/successStories`,
    getHospitalTelemedicineDoctor: `${url}hospital/hospitalTeliDoctors`,
    getHospitalFeatures: `${url}hospital/specialFeatures`,
    getAllCity: `${url}country/listCity`,
    getFeatured: `${url}cms/FeaturedIn`,
    getTopDoctorByState: `${url}country/StateBasedBestDoctor`,
    getDepartmentByCountry: `${url}country/countryBasedDepartment`,
    getStateBasedTreatment: `${url}country/stateBasedTreatments`,
    getCmsPersonalLoan: `${url}cms/personalLoan`,
    postHospitalListEnquiry: `${url}enquiry/addHospitalEnquiry`,
    getBloggDetails: `${url}blogs/blog_view`,
    getRecentBlogPost: `${url}blogs/recentPosts`,
    getBlogHospital: `${url}blogs/bestHospitalForBlog`,
    getBlogDoctor: `${url}blogs/bestDoctorForBlog`,

    getListHospitalCms: `${url}cms/cmsList`,

    getDoctorSuggetion: `${url}doctor/doctorAutocompleteSearch`,
    getHospitalSuggetion: `${url}hospital/hospitalAutocompleteSearch`,
    getTreatmentSuggetion: `${url}treatment/treatmentAutocompleteSearch`,

    getBlogSuccessStories: `${url}blogs/blogSuccessStories`,
    getNewsDetails:`${url}cms/cmsDetails`,
    getDieseases:`${url}diseases/listDiseases`,
    getTeleDoctor:`${url}doctor/teleDoctors`,
    postTelemedicineDoctorEnquiry:`${url}doctor/telemedicinedoctorEnquiry`,
    getTreatments:`${url}treatment/list`







}

