import React, { useEffect, useState } from 'react'
import Hero from '../components/common/hero'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/home/blogdetails.scoped.css'

import placeHolder from '../assets/images/placeholder.png'



import Faq from '../components/common/faq'
import axios from 'axios'
import apiUrl from '../services/apiUrl'
import parse from 'html-react-parser'

function AwardDetailsPage() {
    const { awardId } = useParams()
    const [award, setaward] = useState('')



    useEffect(async () => {
        getAwardDetails()
        // getSingleBlogFaq()



    }, [])


  

    const getAwardDetails = async () => {
        try {
            var response = await axios.post(apiUrl.getNewsDetails, { cms_id: awardId })
            if (response?.data?.data) {
                setaward(response.data.data)
            }
        } catch (error) {
            console.error(error)
        }
    }






    return (
        <>
            <Hero />
            <section className="blog-section">
                <div className="col-12 main-pd">
                    <div className="row blog-detail-contr">
                        <div className="col-12">
                            <img src={award?.image ? `${apiUrl.host}/storage/${award.image}` : placeHolder} alt="" />
                        </div>
                        <div className="col-8">
                            <div className="blog-list">
                                <h2>{award?.title}</h2>
                                <div className="content">
                                    {parse(`${award?.content}`)}
                                </div>
                                <div className="content">
                                    {parse(`${award?.meta_description || ''}`)}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>


            {/* <Faq faqlist={faqs} /> */}
        </>
    )
}

export default AwardDetailsPage