import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { setCountryList } from '../../features/search/countryListSlice'
import { updateSelectedCategory } from '../../features/search/selectedCategorySlice'
import { updateSelectedCountry } from '../../features/search/selectedCountrySlice'
import TreatmentSearchBox from '../search/treatmentSearch'
import { Link } from 'react-router-dom';
import { setIsSearch } from '../../features/search/searchListenSlice'
import DocotorSearchBox from '../search/doctorSearch';
import { setsearchText } from '../../features/search/searchTextSlice'
import EnquiryModal from '../popup/modalenquiry';

import { Modal, Button } from 'antd';








import { Dropdown } from 'react-bootstrap';



// importing icons

import bannerImg from '../../assets/images/home-banner.png'
import doctorIcon from '../../assets/images/doctor-icon.svg'
import hospitalIcon from '../../assets/images/hospital-icon.svg'
import treatmentIcon from '../../assets/images/treatment-icon.svg'
import packageIcon from '../../assets/images/package-icon.svg'
import hotelIcon from '../../assets/images/hotel-icon.svg'
import flightIcon from '../../assets/images/flight-icon.svg'
import visaIcon from '../../assets/images/visa-icon.svg'
import searchIcon from '../../assets/images/search-icon.svg'
import arrowIcon from '../../assets/images/arrow-bg.svg'
import bannerArrowIcon from '../../assets/images/banner-arrow.svg'
import bestDoctorIcon from '../../assets/images/best-doctor-icon.svg'
import rightArrow from '../../assets/images/arrow-right.svg'
import moreIcon from '../../assets/images/more.svg'
import axios from 'axios'
import apiUrl from '../../services/apiUrl'

import doctorChecked from '../../assets/images/doctor_selected.svg'
import hospitalChecked from '../../assets/images/hospital_selected.svg'
import tratmentChecked from '../../assets/images/traetment_selected.svg'

import '../../assets/css/hero.scoped.css'
import HospitalSearchBox from '../search/hospitalSearch';

import {
  HomeOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
  LoadingOutlined,
} from '@ant-design/icons';


function Hero(props) {
  const navigate = useNavigate();
  const [showMenu, setshowMenu] = useState(false)

  const countryList = useSelector(state => state.countrylist.value)
  const selectdCategory = useSelector(state => state.selectedCategory.value)
  const selectedCountry = useSelector(state => state.slectedCountry.value)
  const selectedDept = useSelector(state => state.selectedDept.value)
  const searchText = useSelector(state => state.searchText.value)
  const isSearch = useSelector(state => state.isSearch.value)
  const [selectableCategory, setselectableCategory] = useState([1, 2, 3])

  const [showPopup, setshowPopup] = useState(false)

  const dispatch = useDispatch()

  const getCountry = async () => {
    if (countryList.length === 0) {
      var response = await axios.get(apiUrl.getCountry)
      if (response?.status && response?.data?.data?.country) {
        dispatch(setCountryList(response.data.data.country))
      }
    }
  }

  const hideModal = () => {
    setshowPopup(false)
  }

  const search = async () => {
    dispatch(setIsSearch(isSearch + 1))
    switch (selectdCategory.id) {
      case 2:
        navigate('/hospitals')
        break;
      case 3:
        navigate('/treatment/list')
        break;
      case 1:
        navigate('/doctors')
        break;
      case 0:
        navigate('/doctors')
        break;
    }
  }

  useEffect(() => {
    getCountry()
  }, [])

  return (
    <>


      {<EnquiryModal showPopup={showPopup} hidePopup={hideModal} />}





      <section className="overflw-contr">
        <div className="p-0 main-banner text-center">
          <img src={bannerImg} alt="" className="banner-bg" />
          <div className="banner-content">
            <h1>{props.title || 'THE MEDICAL HAND IN NEED'}</h1>
            <div className="content">Every Right Health Decision Starts Here..!</div>
            <div className="search-contr">
              <div className="search-icons">
                <ul>
                  <li className="selectable-menu" style={{ color: selectdCategory.id === 1 && "#E6771B" }} onClick={() => { dispatch(updateSelectedCategory({ id: 1 })); navigate('/doctors') }}><div className="im"><img src={selectdCategory.id === 1 ? doctorChecked : doctorIcon} alt="" /></div> Doctors</li>
                  <li className="selectable-menu" style={{ color: selectdCategory.id === 2 && "#E6771B" }} onClick={() => { dispatch(updateSelectedCategory({ id: 2 })); navigate('/hospitals') }}><div className="im"><img src={selectdCategory.id === 2 ? hospitalChecked : hospitalIcon} alt="" /></div> Hospital</li>
                  <li className="selectable-menu" style={{ color: selectdCategory.id === 3 && "#E6771B" }} onClick={() => { dispatch(updateSelectedCategory({ id: 3 })); navigate('/treatment/list') }}><div className="im"><img src={selectdCategory.id === 3 ? tratmentChecked : treatmentIcon} alt="" /></div> Treatment</li>
                  <li className="selectable-menu sm-hide" style={{ color: selectdCategory.id === 4 && "#E6771B" }}><div className="im"><img src={packageIcon} alt="" /></div>Package</li>
                  <li className="selectable-menu sm-hide" style={{ color: selectdCategory.id === 5 && "#E6771B" }}><div className="im"><img src={hotelIcon} alt="" /></div> Hotel</li>
                  <li className="selectable-menu sm-hide" style={{ color: selectdCategory.id === 6 && "#E6771B" }}><div className="im"><img src={flightIcon} alt="" /></div> Flight</li>
                  <li className="selectable-menu sm-hide" style={{ color: selectdCategory.id === 7 && "#E6771B" }}><div className="im"><img src={visaIcon} alt="" /></div> Patient story</li>
                  {/* <li className="selectable-menu" onClick={() => { setshowMenu(!showMenu) }}><div className="im"><img src={moreIcon} alt="" /></div> More
                  </li> */}

                  <li><Dropdown className='more-btn'>
                    <Dropdown.Toggle className="selectable-menu sm-hide bg-transparent border-0 text-dark p-0" id="dropdown-basic">
                      <div className="im"><img src={moreIcon} alt="" /></div>
                      More
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="ds-hide"><Link className="dropdown-item" to="/">Package</Link></Dropdown.Item>

                      <Dropdown.Item className="ds-hide"><Link className="dropdown-item" to="/">Hotel</Link></Dropdown.Item>
                      <Dropdown.Item className="ds-hide"><Link className="dropdown-item" to="/">Flight</Link></Dropdown.Item>
                      <Dropdown.Item className="ds-hide"><Link className="dropdown-item" to="/ ">Patient Story</Link></Dropdown.Item>

                      <Dropdown.Item><Link className="dropdown-item" to="/blogs"> Blog</Link></Dropdown.Item>
                      <Dropdown.Item><Link className="dropdown-item" to="/about"> About Us</Link></Dropdown.Item>
                      <Dropdown.Item><a className="dropdown-item">Visa</a></Dropdown.Item>
                      <Dropdown.Item><Link className="dropdown-item" to="/contact">Support / Contact US </Link></Dropdown.Item>
                      <Dropdown.Item><Link className="dropdown-item" to="/medicine">Medicine</Link></Dropdown.Item>
                      <Dropdown.Item><Link className="dropdown-item" to="/telemedicinedoctor">TeleMedicine Doctor</Link></Dropdown.Item>
                      <Dropdown.Item><Link className="dropdown-item" to="/listhospital">List Hospital</Link></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown></li>
                </ul>
              </div>



              {/* <div className="radio-btn-contr">
                {countryList.map(item => <label key={item.id} className={item.id == selectedCountry ? "radio-btn active" : "radio-btn"} onChange={() => { dispatch(updateSelectedCountry(item.id)) }}>{item.name}
                  <input type="radio" id={item.id} checked={item.id == selectedCountry && "true"} name="radio" value={item.name} onChange={() => { dispatch(updateSelectedCountry(item.id)) }} />
                  {item.id == selectedCountry && <span className="checkmark"></span>}
                </label>)}

              </div> */}
              <div className="search-input">

                {selectdCategory.id === 3 && <HospitalSearchBox value={searchText} />}
                {selectdCategory.id === 1 && <HospitalSearchBox value={searchText} />}
                {selectdCategory.id === 2 && <HospitalSearchBox value={searchText} />}


                {!selectableCategory.includes(selectdCategory.id) &&
                  <>
                    <input type="search" placeholder="Search Here!" value={searchText}
                      onChange={(e) => { dispatch(setsearchText(e.target.value)) }}
                    />

                  </>
                }



                <img src={searchIcon} alt="" />
                <button type="submit" onClick={search}>SEARCH</button>





              </div >
            </div >
          </div >

          {
            props.showButton && <div className="banner-arrow">
              <img src={arrowIcon} alt="" />
              <img src={bannerArrowIcon} alt="" className="scroll-arrow" />
            </div>
          }

        </div>
        {props.showButton && <div className="p-0 banner-bottom">
          <h1 style={{ cursor: 'pointer' }} onClick={() => setshowPopup(true)}><img src={bestDoctorIcon} alt="" /><span className='text-white'>Do you have medical problem?<br />ASK NOW FROM BEST DOCTOR</span><img src={rightArrow} alt="" className="arrow-right" /></h1>
        </div>}


        {showMenu && <div className="d-menu" style={{ display: 'block' }}>
          <ul>
            <li className="ds-hide"><Link className="dropdown-item" to="/">Package</Link></li>
            <li className="ds-hide"><Link className="dropdown-item" to="/">Hotel</Link></li>
            <li className="ds-hide"><Link className="dropdown-item" to="/">Flight</Link></li>
            <li className="ds-hide"><Link className="dropdown-item" to="/ ">Visa</Link></li>
            <li><Link className="dropdown-item" to="/blogs">Blog</Link></li>
            <li><Link className="dropdown-item" to="/contact">Support / Contact US </Link></li>
            <li><Link className="dropdown-item" to="/medicine">TeleMedicine</Link></li>
            <li><Link className="dropdown-item" to="/telemedicinedoctor">Speciality Doctor</Link></li>
            <li><Link className="dropdown-item" to="/listhospital">List Hospital</Link></li>
          </ul>
        </div>}

        {/* <div className="suggetion-menu" style={{ backgroundColor: 'red' }}>
          <h3>hello world</h3>
        </div> */}

      </section >


    </>






  )
}

export default Hero
