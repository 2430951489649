import axios from 'axios'
import React, { useState, useEffect } from 'react'

import parse from 'html-react-parser'

import disimIcon from '../../assets/images/dis-im.png'
import apiUrl from '../../services/apiUrl'
import '../../assets/css/treatment/treatment.scoped.css'
import placeHolder from '../../assets/images/placeholder.png'


function TreatmentHead(props) {


    const [diseaseInfo, setdiseaseInfo] = useState({})
    const [showReadMore, setshowReadMore] = useState(false)
    const [diseaseDisc, setdiseaseDisc] = useState('')
    const [treatmentIMage, settreatmentIMage] = useState('')

    useEffect(() => {
        getDiseaseInfo()
        getDiseaseDiscription()

    }, [])


    const getDiseaseInfo = async () => {
        try {
            var response = await axios.post(apiUrl.getTreatmentDises, { treatment_id: props.id })
            if (response?.data?.data?.diseases) {
                setdiseaseInfo(response.data.data.diseases)
            }
        } catch (error) {
            console.log(error)

        }
    }

    const getDiseaseDiscription = async () => {
        try {
            var response = await axios.post(apiUrl.getTreatmentDiseasesDiscription, { treatment_id: props.id })
            if (response?.data?.data?.treatments) {
                setdiseaseDisc(response?.data?.data?.treatments?.description || '')
                settreatmentIMage(response?.data?.data?.treatments?.image || '')


            }
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <section>
            <div className="col-12 main-pd">
                <div className="dis-section">
                    <div className="content-sec">
                        <h1 className="t-600">{diseaseInfo?.title || ''} <span className='text-secondary'>{props?.country && '-'} {props?.country}</span></h1>
                        <div className="content">{parse(`${diseaseInfo?.description || ''}`)}
                        </div>
                        {showReadMore && <span className='mt-3'>
                            {parse(`${diseaseDisc || ''}`)}
                        </span>}
                        {!showReadMore && <button className="read-more" onClick={() => { setshowReadMore(true) }}>Read More</button>}
                    </div>
                    <div className="dis-im">
                       
                        <img src={treatmentIMage ? `${apiUrl.host}/storage/${treatmentIMage}`: placeHolder } alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TreatmentHead
