import React, { useEffect, useState } from 'react'

import TreatmentHead from '../components/common/treatmenthead'
import TreatmentPlans from '../components/common/treatmentplans'
import Treatment from '../components/common/treatment'

import { useParams } from 'react-router-dom'


import Hero from '../components/common/hero'
import Faq from '../components/common/faq'
import axios from 'axios'
import apiUrl from '../services/apiUrl'

function TreatmentPage() {
    const { treatmentId } = useParams()
    const [faq, setfaq] = useState([])
    const [selectedCountry, setselectedCountry] = useState('')

    const getSingleTreatmentFaq = async ()=>{
        try {
            var response = await axios.post(apiUrl.getSingleTreatmentFaq,{treatment_id:treatmentId})
            if(response?.data?.status && response?.data?.data?.treatmentFaq){
                setfaq(response.data.data.treatmentFaq)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getSingleTreatmentFaq()
    }, [])

    const changeCountry = (data)=>{
        setselectedCountry(data)
    }

    return (
        <>
            <Hero />
            <TreatmentHead id={treatmentId} country={selectedCountry}  />
            <TreatmentPlans id={treatmentId} />
            <Treatment id={treatmentId} changeCountry={changeCountry} />
            <Faq faqlist={faq}></Faq>

        </>
    )
}

export default TreatmentPage
