import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Skeleton } from 'antd';

import apiUrl from '../../services/apiUrl';

import 'antd/lib/skeleton/style/index.css'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//icons
import placeHolder from '../../assets/images/placeholder.png'
import arrowDown from '../../assets/images/arrow-down-b.svg'
import building from '../../assets/images/building.png'
import placeMap from '../../assets/images/place-map.svg'
import exploreIcon from '../../assets/images/explore-icon.svg'

import '../../assets/css/hospitalhero.scoped.css'
import { Link } from 'react-router-dom';
import Treatmentsection from './treatmentsection';
import Select from 'react-select'
import HospitalBookPopup from '../popup/hospitalBook';


function Hospitalhero() {
    const [countryList, setcountryList] = useState([])
    const [selectdCountry, setselectdCountry] = useState(1)
    const [hospitalList, sethospitalList] = useState([])
    const [stateList, setstateList] = useState([])
    const [selectedState, setselectedState] = useState('')
    const [departmentList, setdepartmentList] = useState([])
    const [tempHospitalList, settempHospitalList] = useState([])
    const [selectedHospital, setselectedHospital] = useState('')
    const [isLoading, setisLoading] = useState(false)

    const [showPopup, setshowPopup] = useState(false)



    useEffect(async () => {
        getCountry()
    }, [])

    useEffect(() => {
        getTopHospital()
    }, [selectdCountry])

    useEffect(() => {
        getState()
    }, [selectdCountry])

    useEffect(() => {
        getTopHospitalByState()
    }, [selectedState])


    const selectThemes = (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: 'hotpink',
            primary: 'black',
        },
    })


    const hidePopup = () => setshowPopup(false)


    const getCountry = async () => {
        try {
            var response = await axios.get(apiUrl.getCountry)
            if (response?.status && response?.data?.data?.country) {
                setcountryList(response.data.data.country)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getTopHospital = async () => {
        setisLoading(true)
        try {
            var response = await axios.post(apiUrl.getTopHospital, { country_id: selectdCountry })
            if (response?.status && response?.data?.data?.countryHospitals) {
                sethospitalList(response.data.data.countryHospitals)
                settempHospitalList(response.data.data.countryHospitals)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setisLoading(false)

        }
    }

    const getTopHospitalByState = async () => {
        try {
            var response = await axios.post(apiUrl.getTopHospital, { country_id: selectdCountry, state_id: selectedState })
          
            if (response?.status && response?.data?.data?.statehospital) {
            
                sethospitalList(response.data.data.statehospital)
            }

        } catch (error) {
            console.error(error)
        }
    }

    const getState = async () => {
        try {
            var response = await axios.post(apiUrl.getStateListByCountry, { country_id: selectdCountry })
            if (response?.data?.data?.countryBasedState) {
                setstateList(response.data.data.countryBasedState)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrow: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,

        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };


    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },

    //   ]

    const newValues = stateList.map(item => { return { value: item.id, label: item.name } })
    const newCountry = countryList.map(item => ({ value: item.id, label: item.name }))



    return (

        <>

            <HospitalBookPopup hospitalId={selectedHospital} showPopup={showPopup} hidePopup={hidePopup} />

            <section>
                <div className="col-12 hospitals-contr main-pd text-center">
                    <div className="tl-contr float-start w-100">
                        <h1 className="text-start float-start">Hospitals</h1>
                        <div className="float-end">
                            <ul className="country-contr">

                                {countryList[0]?.id && <li style={{ cursor: 'pointer' }} onClick={() => { setselectdCountry(countryList[0]?.id) }} className={countryList[0].id === selectdCountry ? "active" : undefined}>{countryList[0].name}</li>}
                                {countryList[1]?.id && <li style={{ cursor: 'pointer' }} onClick={() => { setselectdCountry(countryList[1]?.id) }} className={countryList[1].id === selectdCountry ? "active" : undefined}>{countryList[1].name}</li>}

                                {countryList.length > 3 && <li>
                                    <Select options={newCountry} theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#f9bf8f',
                                            primary: '#E6771B'
                                        },
                                    })} onChange={(e) => { setselectdCountry(e.value) }}>
                                        {/* <option hidden>More</option>
                                        {countryList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)} */}
                                    </Select>
                                </li>}

                            </ul>

                            <div className="radio-btn-contr">

                                {/* {countryList.map(item => <label key={item.id} className="radio-btn" onChange={() => { setselectdCountry(item.id) }}>{item.name}
                                <input type="radio" id={item.id} checked={item.id === selectdCountry && "true"} name="radio" onChange={() => { setselectdCountry(item.id) }} />
                                {item.id === selectdCountry && <span className="checkmark"></span>}
                            </label>)} */}
                                <div className="row">
                                    <div className="col-12 row">
                                        {stateList[0]?.id && <div className="col">
                                            <label className="radio-btn">{stateList[0]?.name}
                                                <input type="radio" readOnly checked={selectedState === stateList[0]?.id} onClick={() => { setselectedState(stateList[0]?.id) }} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>}

                                        {stateList[1]?.id && <div className="col">
                                            <label className="radio-btn">{stateList[1]?.name}
                                                <input type="radio" readOnly checked={selectedState === stateList[1]?.id} onClick={() => { setselectedState(stateList[1]?.id) }} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>}

                                        {stateList.length > 3 && <div className="col-3">
                                            <Select options={newValues} style={{ width: '100' }} onChange={(e) => { setselectedState(parseInt(e.value)) }}>
                                                {/* <option hidden>More</option> */}
                                                {/* {stateList.map(item => <option key={item.id} value={item.id}>{item.name}</option>)} */}
                                            </Select>
                                        </div>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <Skeleton loading={isLoading} active>
                        <div className="row testimonial">
                            <Slider {...settings}>

                                {hospitalList.map(item =>
                                    <div key={item.id} className="col-4">

                                        <div className="item">
                                            <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" className="w-100" height="240" />
                                            <h2>{item.title}</h2>
                                            <div className="content">
                                                <p className="m-0"><img src={placeMap} alt="" /> {item.address}</p>
                                            </div>
                                            <Link to={`hospital/${item.id}`} className="explore">Explore More <img src={exploreIcon} /></Link>
                                            <button type="button" className="book-now" onClick={()=> {setselectedHospital(item?.id);setshowPopup(true)}}>Book Now</button>
                                        </div>


                                    </div>)}


                            </Slider>
                            {hospitalList.length === 0 && <h4>No Data to Show</h4>}
                        </div>
                    </Skeleton>


                    <div className="tl-contr to-top">
                        <h1>Treatment Offered</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 text-center">


                    </div>
                </div>
            </section>

            <Treatmentsection selectedState={selectedState} />

        </>


    )
}

export default Hospitalhero
