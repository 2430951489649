import { Modal, Button, Form } from 'react-bootstrap';
import apiUrl from '../../services/apiUrl';
import axios from 'axios';



import React, { useState } from 'react'

function TeleDoctorPopup({ showPopup, hidePopup, doctorId }) {
    const [first, setfirst] = useState('')
    const [email, setemail] = useState('')
    const [phone, setphone] = useState('')
    const [message, setmessage] = useState('')
    const [validated, setvalidated] = useState(false)
    const [engError, setengError] = useState(false)
    const [diseasesList, setdiseasesList] = useState([])
    const [docs, setdocs] = useState('')
    const [submiting, setsubmiting] = useState(false)
    const [successMsg, setsuccessMsg] = useState('')
    const [errMsg, seterrMsg] = useState('')


    console.log('docid', doctorId)

    const submitForm = async (e) => {

        setsubmiting(true)
        try {

            var response = await axios.post(apiUrl.postTelemedicineDoctorEnquiry, {
                telemedicine_doctor_id: doctorId,
                name: first,
                email: email,
                message: message,
                contact: phone
            })
            console.log(response.data)

            if (response?.data?.status) {
                // message.success(response.data.message || 'Enquiry Recorded Successfully', 3)
                setsuccessMsg(response?.data?.message || ' Enquiry added Successfully')
                setsubmiting(false)

            } else {
                seterrMsg(response?.data?.message || 'Failed to save Enquiry')
                setsubmiting(false)
                //message.error('Failed Record Enquiry', 3)

            }

        } catch (error) {
            console.log(error)
            setsubmiting(false)
            // message.error('Failed Record Enquiry', 3)
        } finally {
            e.target.reset();
            setdocs('')
            setfirst('')
            setemail('')
            setmessage('')
            setphone('')

        }


    }




    const handleSubmit = (event) => {
        setengError(false)
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setvalidated(true);
        } else {
            setvalidated(false);
            submitForm(event)

        }
    }


    return (
        <>
            <Modal
                show={showPopup}
                keyboard={false}
                centered
                size="md"
                onHide={() => hidePopup()}
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                    <Modal.Header closeButton style={{ border: 0 }}>
                        <Modal.Title>Book Doctor</Modal.Title>
                    </Modal.Header>

                    {successMsg && <span className='text-success' style={{ margin: "auto", display: "table" }}>{successMsg}</span>}
                    {errMsg && <span className='text-danger' style={{ margin: "auto", display: "table" }}>{errMsg}</span>}


                    <Modal.Body>

                        <div></div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name" value={first} onChange={(e) => setfirst(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control required type="email" placeholder="Enter Email" value={email} onChange={(e) => setemail(e.target.value)} />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="phone" placeholder="Enter phone" value={phone} onChange={(e) => setphone(e.target.value)} />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setmessage(e.target.value)} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer style={{ border: 0 }}>
                        <Button variant="secondary" onClick={() => hidePopup()}>
                            Close
                        </Button>
                        <Button style={{ backgroundColor: '#E6771B', borderColor: '#E6771B' }} type="submit" disabled={submiting} >
                            {!submiting ? 'Submit Enquiry' : <> Sending...  <span className="spinner-border spinner-border-sm" role="status" /> </>}
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>


        </>
    )
}





export default React.memo(TeleDoctorPopup)