import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

import { Skeleton, Empty, Pagination } from 'antd';
import 'antd/lib/skeleton/style/index.css'
import 'antd/lib/pagination/style/index.css'
import 'antd/lib/empty/style/index.css'

import placeHolder from '../assets/images/placeholder.png'

import Hero from '../components/common/hero'
import Faq from '../components/common/faq'

import '../assets/css/tratmentlist/treatmentlist.scoped.css'
import axios from 'axios'
import apiUrl from '../services/apiUrl'


function TreatmentlistPage() {
    const [treatmentList, settreatmentList] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [faq, setfaq] = useState([])

    const dept = useSelector(state => state.selectedDept.value)
    const searchText = useSelector(state => state.searchText.value)
    const isSearch = useSelector(state => state.isSearch.value)
    const selectedCountry = useSelector(state => state.slectedCountry.value)


    let PageSize = 6

    const [currentPage, setCurrentPage] = useState(1)

    const currentTableData = React.useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return treatmentList.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, treatmentList])

    useEffect(() => {
        getTreatmentList()
    }, [isSearch])

    useEffect(() => {
        getTreatmentFaq()
    }, [])


    const getTreatmentList = async () => {
        setisLoading(true)

        try {
            var response = await axios.post(apiUrl.getTreatmentList, { tab_id: 3, search_text: searchText })
            console.log(response.data)
            if (response.data.status && response?.data?.data?.treatmentsCategory) {
                settreatmentList(response.data.data.treatmentsCategory)
                setisLoading(false)
            }
        } catch (error) {
            console.log(error, 'error')
            setisLoading(false)

        }

    }


    const getTreatmentFaq = async () => {
        try {
            var response = await axios.get(apiUrl.getTreatmentFaq)
            if (response?.data?.status && response?.data?.data?.allTreatmentFaq) {
                setfaq(response.data.data.allTreatmentFaq)
            }
        } catch (error) {
            console.log(error)

        }
    }




    return (
        <>
            <Hero />

            <section className="treatment-section">
                <div className="row main-pd">
                    <div className="tl-contr float-start w-100 m-0">
                        <h1 className="text-start float-start">Treatment Offered</h1>
                    </div>
                    <Skeleton loading={isLoading} active>

                        {currentTableData.map(item =>
                            <div className="col-4 sj-full">
                               
                                    <div className="t-list text-center">
                                        <div className="img-rd">
                                            <img src={item?.image ? `${apiUrl.host}/storage/${item.image}` : placeHolder} alt="" width="100%" />
                                        </div>
                                        <div className="icon-mn">
                                            <div className="icon">
                                                <img src={item?.logo ? `${apiUrl.host}/storage/${item?.logo}` : placeHolder} alt="" height="70" width="70" />
                                            </div>
                                        </div>
                                        <h2 className='text-dark'>{item?.category_name}</h2>
                                        <ul>
                                            {item?.treatments.map((item,index) => <li key={index} className='text-dark'><Link to={`/treatment/${item?.id}`} className="text-dark">{item?.name}</Link> </li>)}

                                        </ul>
                                    </div>
                            
                            </div>
                        )}

                        {!treatmentList.length && <Empty />}

                    </Skeleton>

                    <div className="text-end mb-2">
                        <Pagination style={{ borderColor: 'red' }} size={'large'} current={currentPage} total={treatmentList.length} onChange={page => setCurrentPage(page)} />

                    </div>


                </div>
            </section>

            <Faq faqlist={faq} />

        </>
    )
}

export default TreatmentlistPage
