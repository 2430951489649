import { Modal, Button, Form } from 'react-bootstrap';
import apiUrl from '../../services/apiUrl';
import axios from 'axios';



import React, { useState } from 'react'

function DoctorEnquiryPopup({ showPopup, hidePopup, doctorId }) {
    const [first, setfirst] = useState('')
    const [email, setemail] = useState('')
    const [phone, setphone] = useState('')
    const [validated, setvalidated] = useState(false)
    const [engError, setengError] = useState(false)
    const [diseasesList, setdiseasesList] = useState([])
    const [submiting, setsubmiting] = useState(false)
    const [successMsg, setsuccessMsg] = useState('')
    const [errMsg, seterrMsg] = useState('')
    const [consulatationList, setconsulatationList] = useState([])
    const [selectedDiseases, setselectedDiseases] = useState('')
    const [doctorSeen, setdoctorSeen] = useState(0)


    console.log('did',doctorId)




    React.useEffect(() => {
        getConsultation()
    }, [])


    const submitForm = async (e) => {
        setsubmiting(true)
        try {
            var response = await axios.post(apiUrl.postDoctorEnquiry, {
                doctor_id: doctorId,
                name: first,
                email: email,
                contact: phone,
                reason: selectedDiseases,
                seen_doctor_before:doctorSeen
            })

            console.log(response?.data)
            if (response?.data?.status) {
                // message.success(response.data.message || 'Enquiry Recorded Successfully', 3)
                setsuccessMsg(response?.data?.message || ' Enquiry added Successfully')
                setsubmiting(false)
            } else {
                seterrMsg(response?.data?.message || 'Failed to save Enquiry')
                setsubmiting(false)
                //message.error('Failed Record Enquiry', 3)
            }

        } catch (error) {
            console.log(error)
            setsubmiting(false)
            // message.error('Failed Record Enquiry', 3)
        } finally {
            e.target.reset();
            setfirst('')
            setemail('')
            setphone('')

        }

    }

    const getConsultation = async () => {
        try {
            var response = await axios.get(apiUrl.getTreatments)
            if (response.data?.data?.treatments) {
                setconsulatationList(response.data.data.treatments)
            }
        } catch (error) {
            console.log(error)
        }
    }



    const handleSubmit = (event) => {
        setengError(false)
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setvalidated(true);
        } else {
            setvalidated(false);
            submitForm(event)

        }
    }


    return (
        <>
            <Modal
                show={showPopup}
                keyboard={false}
                centered
                size="md"
                onHide={() => hidePopup()}
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                    <Modal.Header closeButton style={{ border: 0 }}>
                        <Modal.Title>Book Doctor</Modal.Title>
                    </Modal.Header>

                    {successMsg && <span className='text-success' style={{ margin: "auto", display: "table" }}>{successMsg}</span>}
                    {errMsg && <span className='text-danger' style={{ margin: "auto", display: "table" }}>{errMsg}</span>}


                    <Modal.Body>

                        <div></div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name" value={first} onChange={(e) => setfirst(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control required type="email" placeholder="Enter Email" value={email} onChange={(e) => setemail(e.target.value)} />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="phone" placeholder="Enter phone" value={phone} onChange={(e) => setphone(e.target.value)} />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>What's the reason for your visit?</Form.Label>
                            <Form.Select className='mb-3' onChange={(e) => setselectedDiseases(e.target.value)}>
                                <option hidden>Choose Reason</option>
                                {consulatationList.map(item => <option key={item?.id} value={item?.id}>{item?.name}</option>)}

                            </Form.Select>
                        </Form.Group>
                        <label >Has the patient seen this Doctor before?</label>
                        <br />

                        <Form.Check
                            inline
                            label="Yes"
                            name="group1"
                            type='radio'
                            checked
                            onChange={() => setdoctorSeen(1)}
                        />
                        <Form.Check
                            inline
                            label="No"
                            name="group1"
                            type='radio'
                            onChange={() => setdoctorSeen(0)}

                        />


                    </Modal.Body>
                    <Modal.Footer style={{ border: 0 }}>
                        <Button variant="secondary" onClick={() => hidePopup()}>
                            Close
                        </Button>
                        <Button style={{ backgroundColor: '#E6771B', borderColor: '#E6771B' }} type="submit" disabled={submiting} >
                            {!submiting ? 'Submit Enquiry' : <> Sending...  <span className="spinner-border spinner-border-sm" role="status" /> </>}
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>


        </>
    )
}





export default React.memo(DoctorEnquiryPopup)